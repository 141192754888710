import { ComponentType } from "react";
import { Input, Textarea } from "@mantine/core";
import styled from "styled-components";

import { InputStyledProps } from "./input.interface";
import { colors } from "lib/palette";

const styleInput = (inputTag: ComponentType) => styled(inputTag) <InputStyledProps>`
    width: 100%;
    border: 1px solid ${colors.black_3};
    border-radius: 5px;
    ${({ $error }) => $error && `border: 1px solid ${colors.red_1};`}
    ${({ disabled }) => disabled && "border: none;"}
    background-color: ${({ disabled }) =>
        disabled ? colors.black_4 : colors.white};
    
    &:hover {
        border: 1px solid ${colors.black_2};

        ${({ $error }) => $error && `border: 1px solid ${colors.red_1};`}
        ${({ disabled }) => disabled && "border: none;"}
    }

    &:focus {
        border: 1px solid ${colors.black_2};

        ${({ $error }) => $error && `border: 1px solid ${colors.red_1};`}
        ${({ disabled }) => disabled && "border: none;"}
    }

    .mantine-Input-input {
        border: none;
        font-size: 13px;
        resize: vertical;

        &::placeholder {
            color: ${colors.black_3};
        }
    }
`;

export const StyledInput = styleInput(Input);
export const StyledTextarea = styleInput(Textarea);
