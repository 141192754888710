import { TreeNodes } from "../tree.interface";


export const getTreeDepth = (nodes: TreeNodes<any>) => {
    let maxDepth = 0;

    Object.values(nodes).forEach((node) => {
        let nodeLevel = 1;
        let currentNode = node;

        while (currentNode.parentId) {
            nodeLevel += 1;
            currentNode = nodes[currentNode.parentId];
        }

        if (maxDepth < nodeLevel) {
            maxDepth = nodeLevel;
        }
    });

    return maxDepth;
};
