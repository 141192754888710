import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledFieldContainer = styled.div`
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const StyledErrorMessageContainer = styled.p`
    width: max-content;
    max-width: 100%;
    margin: 8px 0 0;
    color: ${colors.red_1};
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
`;
