export interface SearchInputProps {
    value: string;
    setValue: (data: string) => void;
    placeholder?: string;
    isFullWidth?: boolean;
    testId?: string;
    mask?: RegExp;
}

export const testIds = {
    clearSearchInputButton: "clear-search-input-button",
};

export const SEARCH_INPUT_DELAY = 1000;
