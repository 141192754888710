import { useEffect, useMemo, useState } from "react";
import { DragLayerMonitor, XYCoord, useDragLayer } from "react-dnd";
import { CSSProperties } from "styled-components";

import {
    CustomDragLayerProps,
    SMOOTH_BACK_ANIMATION_TIME,
} from "./draggable.interface";
import { useTreeContext } from "../../provider/use-tree-context";
import { NodeRender } from "../../node-render/node-render";
import { zIndexes } from "lib/z-indexes";
import { Item } from "../../tree.interface";

const getDragLayerStyles = (
    currentOffset: XYCoord | null,
    initialOffset: DOMRect,
    width?: number,
): CSSProperties => {
    return {
        ...(currentOffset ? {} : { transition: `transform ${SMOOTH_BACK_ANIMATION_TIME}ms ease-in-out` }),
        transform: currentOffset
            ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
            : `translate(${initialOffset.left}px, ${initialOffset.top}px)`,
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
        display: "flex",
        width,
        zIndex: zIndexes.draggingItem,
    };
};

export function CustomDragLayer<T>({ nodeRender }: CustomDragLayerProps) {
    const [dragItem, setDragItem] = useState<Item<T> | null>();
    const { isDragging, currentOffset } = useDragLayer(
        (monitor: DragLayerMonitor) => ({
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getSourceClientOffset(),
        }),
    );

    const { nodes, draggingNodeId } = useTreeContext<T>();
    const Render = nodeRender ?? NodeRender;

    useEffect(() => {
        if (draggingNodeId) {
            setDragItem(nodes[draggingNodeId]);
        }
    }, [draggingNodeId, nodes]);

    const dragRowRect = useMemo(
        () =>
            dragItem?.id
                ? document.getElementById(dragItem.id)?.getBoundingClientRect()
                : null,
        [dragItem],
    );

    useEffect(() => {
        if (!isDragging) {
            setTimeout(() => {
                setDragItem(null);
            }, SMOOTH_BACK_ANIMATION_TIME);
        }
    }, [isDragging]);

    if (!dragItem || !dragRowRect) {
        return null;
    }

    return (
        <div
            style={getDragLayerStyles(
                currentOffset,
                dragRowRect,
                dragRowRect?.width,
            )}
        >
            {/* eslint-disable */}
            {/* @ts-ignore */}
            <Render {...dragItem} />
        </div>
    );
}
