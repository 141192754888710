import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";

import {
    SearchInputProps,
    SEARCH_INPUT_DELAY,
    testIds,
} from "./search-input.interface";
import {
    StyledClearButtonContainer,
    StyledClearButton,
    StyledInput,
} from "./search-input.styled";
import { InputField } from "../input-field/input-field.component";
import { Icon } from "../icon/icon.component";
import { Icons } from "../icon/icon.interface";

export const SearchInput = ({
    value,
    setValue,
    placeholder,
    isFullWidth,
    testId,
    mask,
}: SearchInputProps) => {
    const { t } = useTranslation("common");

    const [inputValue, setInputValue] = useState(value);
    const ref = useRef(value);

    useEffect(() => {
        if (ref.current !== inputValue) {
            const timeout = setTimeout(() => {
                setValue(inputValue);
                ref.current = inputValue;
            }, SEARCH_INPUT_DELAY);
            return () => clearTimeout(timeout);
        }
    }, [inputValue, setValue]);

    const handleReset = useCallback(() => {
        setValue("");
        setInputValue("");
    }, [setValue]);

    return (
        <StyledInput isFullWidth={isFullWidth}>
            {value && (
                <StyledClearButtonContainer>
                    <StyledClearButton
                        onClick={handleReset}
                        data-testid={testIds.clearSearchInputButton}
                    >
                        <Icon name={Icons.clearLightSvg} />
                    </StyledClearButton>
                </StyledClearButtonContainer>
            )}
            <InputField
                placeholder={
                    placeholder ?? t("searchInput.placeholder.search", "Search")
                }
                value={inputValue}
                onChange={(v) => {
                    setInputValue((v || "")?.toString());
                }}
                data-testid={testId}
                mask={mask}
            />
        </StyledInput>
    );
};
