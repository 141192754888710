import React from "react";

import { FormFieldProps } from "./form-field.interface";
import { StyledErrorMessageContainer, StyledFieldContainer } from "./form-field.styled";
import { InputLabel } from "../input-label/input-label.component";

export const FormField = (
    {
        children,
        label,
        required,
        errorMessage,
        footer,
        id,
        className
    }: FormFieldProps
) => {
    return (
        <StyledFieldContainer className={className}>
            {label && (
                <InputLabel id={id} label={label} required={required} />
            )}
            {children}
            {errorMessage && (
                <StyledErrorMessageContainer>
                    {errorMessage}
                </StyledErrorMessageContainer>
            )}
            {footer}
        </StyledFieldContainer>
    );
};
